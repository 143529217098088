<div class="block_container">
<form [formGroup]="formGroup">    
    <h1 mat-dialog-title>
      <strong> {{ action }} Degree Transfer Pathways</strong>
    </h1>

    <div id="block_container">
      <div id="bloc_contact_1" class="col-md-3">
        <mat-checkbox
          name="publishprograms"
          [checked]="local_data?.publish"
          (change)="setPublish($event.checked)"
          >Publish&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </mat-checkbox>
        <mat-checkbox
          name="isMasters"
          [checked]="local_data?.isMasters"
          (change)="setMasters($event.checked)"
          >Masters
        </mat-checkbox>
      </div>

      <div id="bloc_contact_1" class="col-md-9 bottom-buttons">
        <button
          mat-button
          (click)="delete()"
          [disabled]="action != 'Update'"
          color="archive"
        >
          <mat-icon color="archive">delete_outline</mat-icon> Delete
        </button>
        <button mat-stroked-button (click)="closeDialog()" color="accent">
          Cancel
        </button>

        <button
          mat-button
          (click)="doAction()"
          [disabled]="!formGroup.valid"
          mat-flat-button
          color="warn">
          Save
        </button>
      </div>
      <br/>
    </div>

    <div *ngIf="action != 'Delete'; else elseTemplateC">
      <div id="block_container_contactDetails">
        <!-- <div id="block_container_contactDetails" *ngIf="action != 'Update'"> -->

        <div id="bloc_contact_detail_1" class="col-md-5">
          <mat-form-field appearance="fill">
            <mat-label>Search for program </mat-label>
            <input
              name="search"
              #inputField
              matInput
              placeholder="{{ local_data?.search }}"
              [(ngModel)]="local_data.search"
              formControlName="search"
              [required]="false"
              (keyup)="onKey($event)"
              (click)="clearSearchInput()"
            />
          </mat-form-field>
        </div>
      </div>
      <div id="block_container_contactDetails">
        <div id="bloc_contact_detail_1" class="col-md-5">
          <!-- <div id="bloc_contact_detail_1" class="col-md-5" *ngIf="action != 'Update'"> -->

          <mat-form-field appearance="fill">
            <mat-label>Program Name </mat-label>
            <mat-select
              ngDefaultControl
              name="program_name"
              [(ngModel)]="local_data.program_name"
              (ngModelChange)="(local_data.program_name)"
              formControlName="program_name"
              multiple
            >
              <mat-option
                *ngFor="let p of programs_filtered"
                [value]="p.program_code"
                (click)="changeProgramCode(p.program_code)"
              >
                {{ p.program_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- display for update only, no edits allowed-->
        <!-- <div id="bloc_contact_detail_1" class="col-md-8" *ngIf="action == 'Update'">
              <mat-form-field appearance="fill">
                  <mat-label>Program Name </mat-label>
                  <input name='display_program_name' matInput placeholder="{{local_data?.display_program_name}}"
                      [(ngModel)]="local_data.display_program_name" formControlName="display_program_name"
                      [required]="false">
              </mat-form-field>
          </div> -->
        <div id="bloc_contact_detail_1" class="col-md-8">
          <mat-form-field appearance="fill">
            <mat-label>Program Code </mat-label>
            <input
              name="program_code"
              matInput
              placeholder="{{ local_data?.program_code }}"
              [(ngModel)]="local_data.program_code"
              formControlName="program_code"
              [required]="false"
            />
          </mat-form-field>
        </div>
      </div>

      <div id="block_container_contactDetails">
        <div id="bloc_contact_detail_1" class="col-md-8">
          <mat-form-field appearance="fill">
            <mat-label>Degree Name </mat-label>
            <input
              name="degree_name"
              matInput
              placeholder="{{ local_data?.degree_name }}"
              [(ngModel)]="local_data.degree_name"
              formControlName="degree_name"
              [required]="false"
            />
          </mat-form-field>
        </div>
      </div>
      <div id="block_container_contactDetails">
        <div class="col-md-12 pathway_description">
          <mat-label>Pathway Description </mat-label>
          <!-- <quill-editor [styles]="{ height: '200px' }" placeholder="{{local_data?.pathway_description}}"
                  [(ngModel)]=" local_data.pathway_description" formControlName="pathway_description"></quill-editor> -->

          <angular-editor
            [(ngModel)]="local_data.pathway_description"
            formControlName="pathway_description"
            [config]="editorConfig"
          ></angular-editor>
        </div>
      </div>

      
    </div>

    <div
      mat-dialog-actions
      class="right-align"
      #elseTemplateCDiv
      *ngIf="action == 'Delete'; else TemplateCDiv"
    >
      <button mat-button (click)="doAction()" mat-flat-button color="warn">
        {{ action }}
      </button>
      <button mat-stroked-button (click)="closeDialog()" color="accent">
        Cancel
      </button>
    </div>
</form>
</div>