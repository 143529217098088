import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidehtml',
})
export class HidehtmlPipe implements PipeTransform {
  /*
  Hide HTML tags on display page
   */
  transform(value: any): any {
    if (value) {
      return value.replace(/(<([^>]+)>)/gi, '');
    }
  }
}
